import React, { useRef, useState } from 'react';
import { useRouter } from 'next/router';
import { signIn } from 'next-auth/react';
import Alert from '@mui/material/Alert';
import { TextField, Typography, Button, Box, Paper, Divider } from '@mui/material';
import ReCAPTCHA from 'react-google-recaptcha';
import useTranslation from 'next-translate/useTranslation';

import validateEmail from '../../utils/validate-email';
import GoogleIcon from '../../../public/providers/google.svg';
import GitHubIcon from '../../../public/providers/github.svg';
import DockIcon from '../../../public/providers/truvera.svg';
import EmailSentImage from '../../../public/email.svg';

const captchaPK = process.env.NEXT_PUBLIC_RECPATCHA_PUBLIC_KEY;

const providerIcons = {
  google: GoogleIcon,
  github: GitHubIcon,
  dockauth: DockIcon,
};

const errors = {
  SignedOut: 'You have been signed out, please try to sign in again.',
  Signin: 'Try signing with a different account.',
  OAuthSignin: 'Try signing with a different account.',
  OAuthCallback: 'Try signing with a different account.',
  OAuthCreateAccount: 'Try signing with a different account.',
  EmailCreateAccount: 'Try signing with a different account.',
  Callback: 'Try signing with a different account.',
  AccountSuspended:
    'Your account or team has been suspended, please contact us if you think this is a mistake.',
  APIUnauthorizedStatus: 'There was an error authorizing, please sign in again.',
  OAuthAccountNotLinked:
    'To confirm your identity, sign in with the same account you used originally. We do not support linking multiple oauth providers to one account. You can also sign in with email.',
  EmailSignin: 'Incorrect email address, or too many attempts. Try again in a few minutes.',
  CredentialsSignin: 'Sign in failed. Check the details you provided are correct.',
  NoPersonalEmails: 'Personal email addresses are not allowed for signup',
  // When throwing the error from the API, the error message is returned as 'error' key
  'Error: NoPersonalEmails': 'Personal email addresses are not allowed for signup',
  default: 'Unable to sign in.',
};

export default function SignIn({ authProviders }) {
  const router = useRouter();
  const [email, setEmail] = useState('');
  const [ssoStage, setSsoStage] = useState(0);
  const [ssoError, setSSOError] = useState();
  const [isSigningInOauth, setIsSigningInOauth] = useState(false);
  const userError = (router.query && router.query.error) || ssoError;
  const captchaRef = useRef(null);
  const { t } = useTranslation(['auth', 'common']);

  function handleEmailChange(e) {
    setEmail(e.target.value && e.target.value.trim());
  }

  async function handleEmailSignin(e) {
    e.preventDefault();

    const captchaToken = captchaRef.current && captchaRef.current.getValue();
    if (captchaPK && !captchaToken) {
      return;
    }

    if (!email || !validateEmail(email)) {
      setSsoStage(0);
      setSSOError('Invalid email');
      return;
    }

    setSsoStage(1);

    try {
      const result = await signIn('email', {
        email,
        captcha: captchaToken,
        redirect: false,
        callbackUrl: `${window.location.origin}/dashboard`,
      });
      if (result.error) {
        setSsoStage(0);
        setSSOError(result.error);
      } else {
        setSsoStage(2);
      }
    } catch (err) {
      console.error(err);
      setSsoStage(0);
      setSSOError('default');
    }
  }

  return (
    <Paper
      sx={{
        padding: '40px',
        borderRadius: '20px',
      }}>
      {ssoStage === 2 ? (
        <>
          <Typography variant="h4" style={{ fontSize: 36 }} gutterBottom>
            Check your email
          </Typography>
          <Typography variant="body1">
            A sign in link has been sent to your email address.
            {process.env.NEXT_PUBLIC_API_URL.indexOf('127.0.0.1') !== -1 && (
              <a
                href={`http://127.0.0.1:3000/api/auth/callback/email?callbackUrl=http%3A%2F%2F127.0.0.1%3A3000&token=e6bdbb85fb37ecb26ad03dd920917c31&email=${encodeURIComponent(
                  email
                )}`}>
                &nbsp;[DEV SIGN IN]
              </a>
            )}
          </Typography>
          <br />
          <br />
          <EmailSentImage />
        </>
      ) : (
        <>
          <Typography variant="h4" style={{ fontSize: '30px', marginBottom: '20px' }}>
            {t('auth:auth-form-title')}
          </Typography>

          <div>
            {userError && (
              <Alert severity="error" style={{ marginTop: '15px', maxWidth: '655px' }}>
                {errors[userError] || userError}
              </Alert>
            )}

            <Box
              id="login-form"
              action="?"
              method="POST"
              component="form"
              onSubmit={handleEmailSignin}>
              {authProviders &&
                Object.values(authProviders).map((provider) => {
                  const Icon = providerIcons[provider.id];
                  return (
                    provider.type === 'oauth' && (
                      <div key={provider.name}>
                        <br />
                        <Button
                          size="large"
                          disabled={isSigningInOauth}
                          onClick={(e) => {
                            e.preventDefault();
                            setIsSigningInOauth(true);
                            signIn(provider.id);
                          }}
                          variant="outlined"
                          fullWidth
                          sx={{
                            color: '#1F2937',
                            border: 'none',
                            background: '#F4F4F5',
                          }}>
                          <Icon style={{ marginRight: '8px', width: '20px', height: '20px' }} />
                          Sign in with {provider.name}
                        </Button>
                      </div>
                    )
                  );
                })}

              <br />

              <Divider sx={{ color: '#51525C' }}>{t('auth:divider-text')}</Divider>

              <br />

              <TextField
                id="email"
                name="email"
                placeholder="Enter your email"
                variant="outlined"
                required
                fullWidth
                value={email}
                onChange={handleEmailChange}
                inputProps={{
                  sx: {
                    height: '31px',
                  },
                }}
                sx={{
                  height: '50px',
                }}
              />

              {captchaPK && (
                <ReCAPTCHA
                  sitekey={captchaPK}
                  ref={captchaRef}
                  style={{
                    margin: '20px auto 0px auto',
                  }}
                />
              )}

              <Button
                size="large"
                type="submit"
                variant="contained"
                color="primary"
                disableElevation
                disabled={ssoStage === 1 || !email || !validateEmail(email)}
                sx={{ marginTop: '24px' }}
                fullWidth>
                Continue
              </Button>
            </Box>
          </div>

          <Typography variant="body2" sx={{ marginTop: '20px', fontSize: '12px' }}>
            By continuing, you agree to our{' '}
            <a href="https://www.dock.io/terms-of-service" target="_blank" rel="noreferrer">
              Terms of Service
            </a>{' '}
            and{' '}
            <a href="https://www.dock.io/privacy" target="_blank" rel="noreferrer">
              Privacy Policy
            </a>{' '}
            and to be contacted by email about our services.
          </Typography>
        </>
      )}
    </Paper>
  );
}
